import { Group } from '@/core/shared';
import { isSwapDemo } from '@/core/utils/src/is';
import { useEffect } from 'react';
import { SwapMessagerWs } from '../websocket/swap';
import { SWAP_SUBSCRIBE_TYPES } from '../websocket/swap/messager';
type fun = (data: any) => void;

const swapws1050 = {
  listeners: new Set<fun>(),
  ws1050(callback: fun, symbols?: string, deps?: any[]) {
    useEffect(() => {
      swapws1050.listeners.add(callback);
      if (swapws1050.listeners.size == 1) {
        if (symbols) {
          SwapMessagerWs.subscribe1050(symbols);
        } else {
          Group.getInstance().then((group) => {
            console.log('group-getInstance', group, group?.getSwapSLIds);
            const symbols = !isSwapDemo() ? group?.getSwapIds.join(',') : group?.getSwapSLIds.join(',');
            SwapMessagerWs.subscribe1050(symbols);
          });
        }
      }
      window.addEventListener(SWAP_SUBSCRIBE_TYPES.ws1050, (e: any) => callback(e.detail));
      return () => {
        swapws1050.listeners.delete(callback);
        window.removeEventListener(SWAP_SUBSCRIBE_TYPES.ws1050, callback);
        if (!swapws1050.listeners.size) {
          SwapMessagerWs.unsubscribe1050();
        }
      };
    }, deps || []);
  },
};

export const useWs1050 = swapws1050.ws1050;
