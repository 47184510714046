// 账户类型
export enum ACCOUNT_TYPE {
  SPOT = 'spot',
  SWAP = 'swap',
  SWAP_U = 'swap_u',
  LITE = 'lite',
  P2P = 'fund',
}

// 账户对应的可划转账户类型
export const ACCOUNT_CAN_TRANSFER_TYPES = {
  [ACCOUNT_TYPE.SPOT]: [ACCOUNT_TYPE.SWAP, ACCOUNT_TYPE.SWAP_U, ACCOUNT_TYPE.LITE, ACCOUNT_TYPE.P2P],
  [ACCOUNT_TYPE.LITE]: [ACCOUNT_TYPE.SPOT, ACCOUNT_TYPE.SWAP_U],
  [ACCOUNT_TYPE.SWAP]: [ACCOUNT_TYPE.SPOT, ACCOUNT_TYPE.SWAP],
  [ACCOUNT_TYPE.SWAP_U]: [ACCOUNT_TYPE.SPOT, ACCOUNT_TYPE.SWAP_U],
  [ACCOUNT_TYPE.P2P]: [ACCOUNT_TYPE.SWAP_U, ACCOUNT_TYPE.SWAP, ACCOUNT_TYPE.SPOT],
};
