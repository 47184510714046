import { useRouter } from '@/core/hooks';
import { Account, Swap } from '@/core/shared';
import { SWAP_DEFAULT_WALLET_ID } from '@/core/shared/src/swap/modules/info/constants';
import { Polling } from '@/core/utils';
import { useEffect, useState } from 'react';
import { ACCOUNT_TYPE } from './types';

type CryptoOptions = {
  sourceAccount: ACCOUNT_TYPE; // from
  targetAccount: ACCOUNT_TYPE; // to
  open: boolean;
};
export type FormatCryptoOptions = {
  id: string;
  crypto: string;
  price: number;
  url?: string;
  canWithdraw?: string;
  wallet?: string;
  walletName?: string;
  icon?: string;
};
export const useFormatCryptoOptions = ({
  sourceAccount,
  targetAccount,
}: {
  sourceAccount: ACCOUNT_TYPE;
  targetAccount: ACCOUNT_TYPE;
}) => {
  const enableLite = process.env.NEXT_PUBLIC_LITE_ENABLE === 'true';
  const usdtKey = 'USDT';

  const { swapAssetsStore, swapUAssetsStore, spotAssetsStore, liteAssetsStore, p2pAssetsStore } = Account.assets;
  // account : sourceAccount
  const getCryptoOptions = ({ account }: { account: ACCOUNT_TYPE }): FormatCryptoOptions[] => {
    const isUsdtType = account === ACCOUNT_TYPE.SWAP_U ? true : false;
    const twoWayMode = Swap.Info.getTwoWayMode(isUsdtType, { withHooks: false });
    const liteAssetsStoreAssets = liteAssetsStore.assets;
    const swapUAssetsStoreWallets = swapUAssetsStore.wallets;
    const swapAssetsStoreWallets = swapAssetsStore.wallets;
    const swapWalletsData = isUsdtType ? swapUAssetsStoreWallets : swapAssetsStoreWallets;
    // isUsdtKey: 如果sourceAccount是SPOT或P2P类型，isUsdtKey将检查targetAccount是否是LITE或SWAP_U；
    // 如果不是，isUsdtKey将检查sourceAccount本身是否是LITE或SWAP_U。如果任一条件为真，isUsdtKey将为true，否则为false。
    const isUsdtKey = [ACCOUNT_TYPE.LITE, ACCOUNT_TYPE.SWAP_U].includes(
      [ACCOUNT_TYPE.SPOT, ACCOUNT_TYPE.P2P].includes(sourceAccount) ? targetAccount : sourceAccount
    );
    const swapPositions = Swap.Order.getPosition(isUsdtType);
    const spotAssetsList = spotAssetsStore.allSpotAssets;
    const p2pAssetsList = p2pAssetsStore.allP2pAssets;
    const isSwapKey = targetAccount === ACCOUNT_TYPE.SWAP;
    const isP2pKey = [ACCOUNT_TYPE.P2P].includes(
      [ACCOUNT_TYPE.SPOT].includes(sourceAccount) ? targetAccount : sourceAccount
    );
    const swapKeys = isUsdtKey
      ? ['USDT']
      : Object.keys(swapWalletsData.find((v) => v.wallet === SWAP_DEFAULT_WALLET_ID)?.accounts || {}).map((key) =>
          key.split('-')[0]?.toUpperCase()
        ); //  ['BTC', 'XRP', 'DOT', 'ETH', 'DOGE']
    switch (account) {
      case ACCOUNT_TYPE.SPOT:
        const p2pKeys = p2pAssetsList.map((item: any) => item.currency);
        const spotToSwapOrP2p = isP2pKey ? p2pKeys : swapKeys;
        return spotAssetsList
          .filter(({ currency }) => spotToSwapOrP2p.includes(currency))
          .map((item) => {
            return {
              id: isUsdtKey ? usdtKey : `${item.currency}-USD`,
              price: item.balance,
              crypto: isUsdtKey ? usdtKey : item.currency?.replace(/-usdt?$/i, ''),
            };
          });
      case ACCOUNT_TYPE.SWAP:
      case ACCOUNT_TYPE.SWAP_U:
        const options: FormatCryptoOptions[] = [];

        const calcPositionData = Swap.Calculate.positionData({
          usdt: isUsdtType,
          data: swapPositions,
          twoWayMode: twoWayMode,
        });

        swapWalletsData.forEach((data) => {
          const assetsData = data.accounts;
          return Object.keys(assetsData).map((key) => {
            const item = assetsData[key];
            const quoteId = `${key.toUpperCase()}-${isUsdtType ? 'USDT' : 'USD'}`;
            const walletId = data.wallet;
            const { allCrossIncomeLoss } = calcPositionData.wallets[walletId] || {};
            const calcItem = calcPositionData.wallets?.[walletId]?.data?.[quoteId];
            const balanceData = Swap.Assets.getBalanceData({
              code: quoteId,
              usdt: isUsdtType,
              withHooks: false,
              walletId,
            });
            let price = Swap.Calculate.balance({
              isTransfer: true,
              usdt: isUsdtType,
              balanceData: balanceData,
              isCross: isUsdtType ? true : Swap.Info.getIsCrossByPositionData(quoteId, swapPositions),
              crossIncome: Number((isUsdtType ? allCrossIncomeLoss : calcItem?.crossIncomeLoss) || 0),
              twoWayMode,
            });
            // if (
            //   balanceData.bonusAmount > 0 &&
            //   [...swapPendings, ...swapPositions].filter((v) => v.subWallet === walletId).length > 0
            // ) {
            //   price = 0;
            // }
            options.push({
              id: quoteId.toUpperCase(),
              price: price,
              crypto: isUsdtType ? usdtKey : quoteId.toUpperCase().replace(/-usd$/i, ''),
              canWithdraw: item.canWithdrawAmount,
              wallet: walletId,
              url: data.url,
              walletName: Swap.Assets.walletFormat(data)['alias'],
              icon: data['pic'],
            });
          });
        });
        return options;
      case ACCOUNT_TYPE.P2P:
        // U 本位 & 币本位
        // p2p 有自己的币种列表，不管source还是target 只要是p2p 一律使用p2p的列表
        const isSwapU = sourceAccount === ACCOUNT_TYPE.SWAP_U || targetAccount === ACCOUNT_TYPE.SWAP_U;
        if (isSwapU || isSwapKey) {
          return p2pAssetsList
            .filter(({ currency }: { currency: string }) => swapKeys.includes(currency))
            .map((item: any) => {
              return {
                id: `${item.currency}-USD`,
                crypto: item.currency,
                price: item.balance || 0,
              };
            });
        }
        return p2pAssetsList.map((item: any) => {
          return {
            id: `${item.currency}`,
            crypto: item.currency,
            price: item.balance || 0,
          };
        });

      case ACCOUNT_TYPE.LITE:
        if (enableLite) {
          return [
            {
              id: usdtKey,
              crypto: usdtKey,
              price: liteAssetsStoreAssets?.money,
            },
          ];
        }
        return [];
    }
  };

  return { getCryptoOptions };
};
export const useCryptoOptions = (props: CryptoOptions) => {
  const [cryptoOptions, setCryptoOptions] = useState<
    { id: string; crypto: string; price: number; wallet?: string; pic?: string; remark?: string }[]
  >([]);
  const { sourceAccount, targetAccount, open } = props;

  const isUsdtType = sourceAccount === ACCOUNT_TYPE.SWAP_U ? true : false;
  const account = sourceAccount;

  const swapPositions = Swap.Order.getPosition(isUsdtType);
  const { spotAssetsStore, liteAssetsStore } = Account.assets;
  const router = useRouter();
  const enableP2P = router.query.locale !== 'zh';
  const { getCryptoOptions } = useFormatCryptoOptions({ sourceAccount, targetAccount });

  // useWs1060Position(
  //   () => {
  //     if (!open) return;
  //     Swap.Assets.fetchBalance(isUsdtType);
  //     Swap.Order.fetchPosition(isUsdtType);
  //   },
  //   [isUsdtType],
  //   { open }
  // );

  const handleCryptoOptions = async () => {
    const options = await getCryptoOptions({ account });
    setCryptoOptions(options);
  };
  useEffect(() => {
    handleCryptoOptions();
  }, [swapPositions, open, spotAssetsStore.allSpotAssets, liteAssetsStore.assets?.money]);

  useEffect(() => {
    if (!open) return;
    const fetchAssetsStore = async () => {
      if (enableP2P) {
        await Promise.all([Account.assets.getAllSpotAssets(true), Account.assets.getAllP2PAssets(true)]);
      } else {
        await Account.assets.getAllSpotAssets(true);
      }
    };
    const polling = new Polling({
      interval: 5000,
      callback: fetchAssetsStore,
    });
    polling.start();
    return () => polling.stop();
  }, [sourceAccount, targetAccount, open]);

  return cryptoOptions;
};
