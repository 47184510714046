import { getCookie } from '@/core/utils';
import { SWAP_SUBSCRIBE_TYPES, WS } from './messager';

export class SwapMessagerWs {
  private static _instance: WS;

  public static getInstance(): WS {
    if (!this._instance) this._instance = new WS();
    return this._instance;
  }

  private static _symbols1050: string;

  // 1050订阅
  public static subscribe1050(symbols: string): void {
    // if (this._symbols1050 == symbols) {
    //   return;
    // } else {
    //   this.unsubscribe1050();
    //   this._symbols1050 = symbols;
    // }
    // this.getInstance().send({
    //   client: 'web',
    //   cmid: SWAP_SUBSCRIBE_TYPES.ws1050,
    //   event: 'PP_FLAGPRICE',
    //   symbols,
    // });
    this.getInstance().send({
      client: 'web',
      cmid: SWAP_SUBSCRIBE_TYPES.ws1090,
      event: 'PP_FLAGPRICE',
    });
  }

  // 1050取消订阅
  public static unsubscribe1050(): void {
    // if (!this._symbols1050) return;
    // this.getInstance().send({
    //   client: 'web',
    //   cmid: SWAP_SUBSCRIBE_TYPES.ws1050,
    //   cancel: true,
    //   event: 'PP_FLAGPRICE',
    //   symbols: this._symbols1050,
    // });
    this.getInstance().send({
      client: 'web',
      cmid: SWAP_SUBSCRIBE_TYPES.ws1090,
      cancel: true,
      event: 'PP_FLAGPRICE',
    });
    this._symbols1050 = '';
  }

  // 1060订阅
  public static subscribe1060(): void {
    this.getInstance().send({
      client: 'web',
      cmid: SWAP_SUBSCRIBE_TYPES.ws1060,
      event: 'PP_POSITION',
      token: getCookie('TOKEN'),
    });
  }

  // 1060取消订阅
  public static unsubscribe1060(): void {
    this.getInstance().send({
      client: 'web',
      cancel: true,
      cmid: SWAP_SUBSCRIBE_TYPES.ws1061,
      event: 'PP_POSITION',
    });
  }

  // 1080订阅
  public static subscribe1080(): void {
    this.getInstance().send({
      client: 'web',
      cmid: SWAP_SUBSCRIBE_TYPES.ws1080,
      event: 'SWAP_USER_EVENT',
      token: getCookie('TOKEN'),
    });
  }

  // 1080取消订阅
  public static unsubscribe1080(): void {
    this.getInstance().send({
      client: 'web',
      cancel: true,
      cmid: SWAP_SUBSCRIBE_TYPES.ws1080,
      event: 'PP_POSITION',
    });
  }
}
