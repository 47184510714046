import { swapGetPublicCommonErrorsApi } from '@/core/api';
import { LANG } from '@/core/i18n';
import { useWs1060Position } from '@/core/network';
import { Swap } from '@/core/shared';
import { resso } from '@/core/store';
import { message, playAudio } from '@/core/utils';
import { useEffect } from 'react';

export const OrderListener = () => {
  const { tradeNoticeSound } = Swap.Info.getTradePreference(Swap.Trade.base.isUsdtType);
  const codeMessage = useCodeMessage();
  useWs1060Position(
    (data) => {
      data = { types: [data] };
      // console.log('data', data);
      try {
        data?.types?.forEach((item: any) => {
          let { code, type, audio, bus } = item || [];
          code = Number(code);
          let info = (codeMessage as any)[code];

          if (info) {
            message.error(info, 1);
          } else {
            switch (code) {
              case 200:
                if (['CLOSED', 'ADD_BOOK'].includes(type)) {
                  // 平仓 和 下单 成功
                  // message.success(LANG('下单成功'), 1);
                } else if (['CLOSED_ALL'].includes(type)) {
                  message.success(
                    LANG('成功：{num1}, 失败：{num2}', {
                      num1: item?.s || 0,
                      num2: item?.f || 0,
                    }),
                    1
                  );
                } else if (['editOrder'].includes(bus)) {
                  message.success(LANG('修改成功'), 1);
                }

                break;
              default:
            }
          }
          if (`${audio}` === '1' && tradeNoticeSound) {
            playAudio('/static/music/swap_order_sound.mp3');
          }
        });
      } catch {}
    },
    [tradeNoticeSound, codeMessage]
  );

  return <></>;
};
const messageStore = resso<any>({
  data: [],
  fetchPromise: null,
  fetchData: async () => {
    if (!messageStore.fetchPromise) {
      messageStore.fetchPromise = messageStore.fetchDataPromise();
    }
  },
  fetchDataPromise: async () => {
    const reuslt = await swapGetPublicCommonErrorsApi();
    if (reuslt.code === 200) {
      messageStore.data = reuslt.data;
    }
  },
});
export const useCodeMessage = () => {
  const { data } = messageStore;
  useEffect(() => {
    messageStore.fetchData();
  }, []);

  return { ...CODE_MESSAGES(), ...data };
};
export const CODE_MESSAGES = () => ({
  1000024: LANG('仓位数量为0，不能下平仓单'),
  100020: LANG('下单精度不合法'),
  100028: LANG('下单价低于最低下单价格'),
  100027: LANG('下单价超过最大下单价格'),
  100034: LANG('下单价低于强平价'),
  100033: LANG('下单价超过强平价'),
  100012: LANG('保证金不够'),
  100007: LANG('持仓被锁定'),
  100048: LANG('超出最大委托单数量'),
  100010: LANG('下单被拒绝，下单数量超过当前杠杆最大可开数量'),
  100008: LANG('下单数量不在允许范围'),
  100022: LANG('委托价格必须大于零'),
  200008: LANG('合约不存在'),
  100049: LANG('市价委托失败'),
  904086: LANG('下单失败，当前最新价格与标记价格价差已超出设定阈值'),
  800011: LANG('子钱包功能维护中'),
  800012: LANG('风险限额变更，限制开新仓'),
  100051: LANG('杠杆已超出最大杠杆倍数'),
  100052: LANG('做多方向的激活价格必须大于开仓价和最新价'),
  100053: LANG('做空方向的激活价格必须小于开仓价和最新价'),
  200020: LANG('用戶手动取消订单'),
  200021: LANG('用戶手动取消订单'),
  200022: LANG('ICO订单模式未成交部分被自动取消'),
  200023: LANG('PostOnly订单，条件未被成功触发，因此被取消'),
  200024: LANG('市价单沒有可撮合的对手方订单'),
  200025: LANG('仓位被強平接管后，当前合约的所有订单被取消'),
  200026: LANG('沒有仓位时，下平仓单会被取消'),
  200027: LANG('強平时，批量取消止盈止损订单'),
  200028: LANG('价格保护机制范围內，沒有可撮合对手方的订单'),
  200029: LANG('条件委托触发生成活动委托时，订单保证金不足，会被自动取消'),
  200030: LANG('仓位被ADL，订单会被自动取消'),
  200031: LANG('持仓时减仓，最终成交仓位数量不够，剩余的未成交委托订单会取消'),
  200032: LANG('仓位数量为0或仓位方向变化时，触发取消用户订单'),
  200033: LANG('撮合引擎判断零冻结订单和仓位数量不一致，撤销订单'),
  200034: LANG('FOK订单未立即成交，被自动取消。'),
});
