// 验证邮箱
export const isEmail = (email: string): boolean => {
  return /^[a-z0-9A-Z]+([._\\-]*[a-z0-9A-Z_])*@([a-z0-9A-Z]+[-a-z0-9A-Z]*[a-z0-9A-Z]+.){1,63}[a-z0-9A-Z]+$/.test(email);
};

// 手机号
export const isPhoneNumber = (phone: string): boolean => {
  return /^\d{5,15}$/.test(phone);
};

/**
 * 登录注册密码正则判断
 * 必须包含至少一个字母和一个数字。
 * 长度在 6 到 30 个字符之间。
 * 允许使用字母、数字和特殊字符。
 * 用于登录密码的判断
 */
export const loginNRegisterPasswordRegex = /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9!@#$%^&*?"'()+,\-./:;<>[\]_`{|}~]{6,30}$/;
export const assetsPasswordRegex = /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9!@#$%^&*?"'()+,\-./:;<>[\]_`{|}~]{6,16}$/;
export const isPassword = (password: string): boolean => {
  return loginNRegisterPasswordRegex.test(password);
};
// 资金密码校验 6-16位
export const isAssetsPassword = (password: string): boolean => {
  return assetsPasswordRegex.test(password);
};

// 6位验证码
export const isCaptcha = (captcha: string): boolean => {
  return /^\d{6}$/.test(captcha);
};

// 4-20位防钓鱼码
export const isPhishing = (phishing: string): boolean => {
  return /^[0-9a-zA-z]{4,20}$/.test(phishing);
};

// 地址地址
export const isUrl = (url: string): boolean => {
  return /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/.test(url);
};
const conditions = [{ regex: loginNRegisterPasswordRegex }, { regex: /[A-Z]/ }, { regex: /[a-z]/ }, { regex: /\d/ }];
// Check each condition
const isConditionMet = (regex: RegExp, password: string) => regex.test(password);

/**
 * 用于注册密码的判断
 */
export const isVipRegisterPasswordValid = (password: string) => {
  return conditions.every((condition) => isConditionMet(condition.regex, password));
};
// 单个数字或者字母
export const isSingleCharNumberOrLetter = (input: string): boolean => {
  return /^[a-zA-Z0-9]$/.test(input);
};

// 一个或者多个数字或者字母
export const isNumberOrLetter = (input: string): boolean => {
  return /^[a-zA-Z0-9]+$/.test(input);
};

// 全是正整数
export const isPositiveInteger = (str: string): boolean => {
  return /^\d+$/.test(str);
};
