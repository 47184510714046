import { isSwapDemo } from '@/core/utils/src/is';
import { NetWorkInfo } from '@/core/utils/src/network-info';
import { WorkerStore } from '@/core/workers';

export enum SWAP_SUBSCRIBE_TYPES {
  ws1000 = '1000', // 心跳
  ws1050 = '1050', // 订阅交易对(标记价格等)
  ws1090 = '1090', // 订阅交易对(标记价格等)
  ws1051 = '1051', // 取消订阅交易对(标记价格等)
  ws1060 = '1060', // 持仓变动
  ws1070 = '1070', // 委托更新
  ws1080 = '1080', // 持仓更新
  ws1061 = '1061', // 持仓变动
}

class WS {
  private ws?: WebSocket;
  private debug: boolean = true;
  private messages: Array<any> = []; // 消息队列，用于重连时发送消息
  private forceClose: boolean = false; // 是否强制关闭ws
  private isOpen: boolean = false; // ws是否连接成功
  private index: number = 0;
  private type: string = 'swap-messager';
  private ws1050Map: { [key: string]: any } = {};

  constructor() {
    this.connect();
  }

  private connect(): void {
    if (this.ws) return;
    this.ws = new WebSocket(this.getWsUrl());
    this.ws.onclose = this.onclose.bind(this);
    this.ws.onerror = this.onerror.bind(this);
    this.ws.onopen = this.onopen.bind(this);
    this.ws.onmessage = this.onmessage.bind(this);
  }

  private onmessage(event: MessageEvent): void {
    WorkerStore.wsWorker.parse(event.data).then((data: any) => {
      if (data.cmid === SWAP_SUBSCRIBE_TYPES.ws1000) {
        setTimeout(() => this.heartbeat(), 3000);
      }
      if (data.cmdId === SWAP_SUBSCRIBE_TYPES.ws1050) {
        const { symbol, currentPrice, fundRate, indexPrice } = data.dataMap;
        this.ws1050Map[symbol] = { currentPrice, fundRate, indexPrice };
        window.dispatchEvent(new CustomEvent(SWAP_SUBSCRIBE_TYPES.ws1050, { detail: this.ws1050Map }));
      }
      if (data.cmdId === SWAP_SUBSCRIBE_TYPES.ws1090) {
        let result = [];
        try {
          result = JSON.parse(data.data);
          result.forEach(({ c: currentPrice, p: indexPrice, r: fundRate, s: symbol }: any) => {
            this.ws1050Map[symbol] = { currentPrice, fundRate, indexPrice };
          });
        } catch (e) {
          console.log('1090 JSON.stringify error');
        }
        window.dispatchEvent(new CustomEvent(SWAP_SUBSCRIBE_TYPES.ws1050, { detail: this.ws1050Map }));
      }
      if (data.cmdId === SWAP_SUBSCRIBE_TYPES.ws1060) {
        window.dispatchEvent(new CustomEvent(SWAP_SUBSCRIBE_TYPES.ws1060, { detail: data }));
      }
      if (data.cmdId === SWAP_SUBSCRIBE_TYPES.ws1070) {
        window.dispatchEvent(new CustomEvent(SWAP_SUBSCRIBE_TYPES.ws1070, { detail: data.data }));
      }
      if (data.cmdId === SWAP_SUBSCRIBE_TYPES.ws1080) {
        let result: any = {};
        try {
          result = JSON.parse(data.data);
        } catch (e) {
          console.log('1080 JSON.stringify error');
        }
        if (result.e === 'PP_POSITION') {
          window.dispatchEvent(new CustomEvent(SWAP_SUBSCRIBE_TYPES.ws1060, { detail: result }));
        } else {
          window.dispatchEvent(new CustomEvent(SWAP_SUBSCRIBE_TYPES.ws1080, { detail: result }));
        }
      }
    });
  }

  private onopen(event: Event): void {
    NetWorkInfo.getInstance().setWsSwapMessagerOnLine(true);
    this.log('ws open', event);
    this.isOpen = true;
    this.heartbeat();
    this.messages.forEach((data) => this.sendMessage(data));
  }

  private onclose(event: CloseEvent): void {
    NetWorkInfo.getInstance().setWsSwapMessagerOnLine(false);
    this.log('ws close', event);
    this.ws = undefined;
    this.isOpen = false;
    if (!this.forceClose) {
      this.index++;
      setTimeout(() => {
        this.connect();
      }, 1000 * this.index);
    }
  }

  private onerror(event: Event): void {
    this.log('ws error', event);
    this.ws = undefined;
    this.isOpen = false;
    if (!this.forceClose) {
      this.index++;
      setTimeout(() => {
        this.connect();
      }, 1000 * this.index);
    }
  }

  private sendMessage(data: any): void {
    if (!this.ws) return;
    if (this.ws.readyState !== 1) return;
    this.ws?.send(JSON.stringify(data));
  }

  private getWsUrl(): string {
    const path = !isSwapDemo() ? 'messager' : 'testnetmessager';
    if (process.env.NODE_ENV === 'development') {
      return `wss://new-bipc-1.bydtms.com/${path}`;
    }

    return `wss://${window.location.host}/${path}`;
  }

  private log(str: string, ...args: any[]): void {
    this.debug && console.log(`%c${this.type}`, 'color: red', str, ...args);
  }

  private heartbeat(): void {
    if (!this.isOpen) return;
    this.sendMessage({ cmid: SWAP_SUBSCRIBE_TYPES.ws1000, client: 'web' });
  }

  public send(data: { cmid: SWAP_SUBSCRIBE_TYPES; [key: string]: any }): void {
    if (!data) return;
    this.isOpen && this.sendMessage(data);

    // 接收到消息后，将消息放入消息队列,等待连接成功后发送，如果是取消订阅消息，则将对应的订阅消息从消息队列中删除
    if (String(data.cmid).substr(-1) === '1') {
      const index = this.messages.findIndex(({ cmid }) => +cmid === +data.cmid - 1);
      if (index !== -1) this.messages.splice(index, 1);
      return;
    }
    const index = this.messages.findIndex(({ cmid }) => cmid === data.cmid);
    if (index === -1) {
      this.messages.push(data);
    } else {
      this.messages.splice(index, 1);
      this.messages.push(data);
    }
  }

  public close(): void {
    this.forceClose = true;
    this.ws?.close();
  }
}

export { WS };
