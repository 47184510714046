import { Account } from '@/core/shared';
import { DependencyList, useEffect } from 'react';
import { SwapMessagerWs } from '../websocket/swap';
import { SWAP_SUBSCRIBE_TYPES } from '../websocket/swap/messager';
type fun = (data: any) => void;

const swapws1080 = {
  listeners: new Set<fun>(),
  ws1080(callback: fun, deps?: DependencyList, { open }: { open: boolean } = { open: true }) {
    useEffect(() => {
      swapws1080.listeners.add(callback);
      const listenerCallback = (e: any) => callback(e.detail);
      window.addEventListener(SWAP_SUBSCRIBE_TYPES.ws1080, listenerCallback);

      open && Account.isLogin && SwapMessagerWs.subscribe1080();
      return () => {
        swapws1080.listeners.delete(callback);
        window.removeEventListener(SWAP_SUBSCRIBE_TYPES.ws1080, listenerCallback);
        if (!swapws1080.listeners.size) {
          SwapMessagerWs.unsubscribe1080();
        }
      };
    }, [...(deps ?? []), open]);
  },
};

export const useWs1080 = swapws1080.ws1080;
