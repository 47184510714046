import React, { InputHTMLAttributes } from 'react';

export enum INPUT_TYPE {
  EMAIL = 'email',
  PASSWORD = 'password',
  REGISTER_PASSWORD = 'register_password',
  PHONE = 'phone',
  CAPTCHA = 'captcha',
  NORMAL_TEXT = 'normal_text',
  RESET_PASSWORD = 'reset_password',
  ANTI_PHISHING_CODE = 'anti_phishing_code',
  ASSETS_PASSWORD = 'assets_password',
  RESET_ASSETS_PASSWORD = 'RESET_ASSETS_PASSWORD',
  PIN_CODE = 'pin_code',
  ALPHA_NUMERIC = 'alpha_numeric',
}
export interface BasicInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | JSX.Element;
  placeholder?: string;
  type: INPUT_TYPE;
  children?: React.ReactNode;
  onInputChange?: (value: string, hasError?: boolean) => void;
  withBorder?: boolean;
  value?: string;
  className?: string;
  customErrorTips?: string;
  hideErrorTips?: boolean;
  prefix?: any;
  suffix?: any;
  showPwd?: boolean;
  onInputBlur?: (value: string) => void;
  onInputFocus?: (value: string) => void;
}

export interface PinInputProps {
  label?: string;
  ruleTip?: string;
  length: number;
  onChange: (value: string) => void;
  className?: string;
}
