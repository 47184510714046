import { Account } from '@/core/shared';
import { isSwapDemo } from '@/core/utils';
import { DependencyList, useEffect } from 'react';
import { SwapMessagerWs } from '../websocket/swap';
import { SWAP_SUBSCRIBE_TYPES } from '../websocket/swap/messager';
type fun = (data: any) => void;

const swapws1060 = {
  listeners: new Set<fun>(),
  ws1060(callback: fun, deps?: DependencyList, { open }: { open: boolean } = { open: true }) {
    const demo = isSwapDemo();
    useEffect(() => {
      swapws1060.listeners.add(callback);
      const listenerCallback = (e: any) => callback(e.detail);

      window.addEventListener(SWAP_SUBSCRIBE_TYPES.ws1060, listenerCallback);
      if (swapws1060.listeners.size == 1) {
        demo && open && Account.isLogin && SwapMessagerWs.subscribe1060();
      }
      return () => {
        swapws1060.listeners.delete(callback);
        window.removeEventListener(SWAP_SUBSCRIBE_TYPES.ws1060, listenerCallback);
        if (!swapws1060.listeners.size) {
          demo && SwapMessagerWs.unsubscribe1060();
        }
      };
    }, [...(deps ?? []), open]);
  },
};

export const useWs1060 = swapws1060.ws1060;
export const useWs1060Position = (callback: fun, deps?: DependencyList, { open }: { open: boolean } = { open: true }) => {
  useWs1060(
    (data) => {
      if ([data?.event, data?.e].includes('PP_POSITION')) {
        return callback(data);
      }
    },
    deps,
    { open }
  );
};
