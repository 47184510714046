import React from 'react';

type TInputElement = HTMLInputElement | HTMLTextAreaElement;

export interface CompositionsResult {
  onChange: (event: React.ChangeEvent<TInputElement>) => void;
  onComposition: (event: React.CompositionEvent<TInputElement>) => void;
}

function useCompositions(onInputCallback?: (value: string) => void): CompositionsResult {
  const compositionLockRef = React.useRef<boolean>(false);
  const handleChange = (newValue: string) => {
    onInputCallback && onInputCallback(newValue); // 使用最新的值调用回调
  };

  const onChange = (event: React.ChangeEvent<TInputElement>) => {
    const newValue = event.target.value;
    handleChange(newValue);
  };

  const onComposition = (event: React.CompositionEvent<TInputElement>) => {
    if (event.type === 'compositionend') {
      compositionLockRef.current = false;
      // 确保使用最新的输入值
      handleChange(event.currentTarget.value);
    } else {
      compositionLockRef.current = true;
    }
  };

  return {
    onChange,
    onComposition,
  };
}

export { useCompositions };
