import CommonIcon from '@/components/common-icon';
import { Svg } from '@/components/svg';
import { WalletAvatar } from '@/components/wallet-avatar';
import { LANG } from '@/core/i18n';
import { Swap } from '@/core/shared';
import { SWAP_BOUNS_WALLET_KEY } from '@/core/shared/src/swap/modules/assets/constants';
import { clsx } from '@/core/utils';
import { ReactNode, useEffect, useState } from 'react';
import { ACCOUNT_TYPE } from './types';
import { FormatCryptoOptions } from './use-crypto-options';

const Expand = ({
  children,
  value,
}: {
  children: (args: { expand: boolean; setExpand: any }) => ReactNode;
  value: boolean;
}) => {
  const [expand, setExpand] = useState(value);
  useEffect(() => {
    setExpand(value);
  }, [value]);
  return <>{children({ expand, setExpand })}</>;
};

export const DropdownMenuItem = ({
  getCryptoOptions,
  opt,
  i,
  selectedValue,
  crypto,
  sourceAccount,
  targetAccount,
  positiveTransfer,
  onChange,
  setOpen,
  img,
  wallets,
}: {
  getCryptoOptions: ({ account }: { account: ACCOUNT_TYPE }) => FormatCryptoOptions[];
  opt: {
    label: string;
    value: ACCOUNT_TYPE;
    icon: string;
  };
  i: number;
  selectedValue: string;
  crypto: string;
  sourceAccount: ACCOUNT_TYPE;
  targetAccount: ACCOUNT_TYPE;
  positiveTransfer: boolean;
  onChange: (args: { value: ACCOUNT_TYPE; positiveTransfer: boolean; wallet?: string }) => void;
  setOpen: any;
  img: string;
  wallets: string[];
}) => {
  const wallet = wallets[positiveTransfer ? 0 : 1];
  let swapType = false;
  const cryptoOptionsOrigin = getCryptoOptions({ account: opt.value });
  const activeType = selectedValue === opt.value;
  let cryptoOptions = cryptoOptionsOrigin.filter((v) => v.crypto.toUpperCase() === crypto);

  let children: {
    id: string;
    crypto: string;
    price: number;
    canWithdraw?: string | undefined;
    wallet?: string | undefined;
    walletName?: string | undefined;
    icon?: string | undefined;
  }[] = [
    {
      ...cryptoOptions?.[0],
      walletName: opt.label,
      wallet: '',
    },
  ];

  if ([ACCOUNT_TYPE.SWAP, ACCOUNT_TYPE.SWAP_U].includes(opt.value)) {
    swapType = true;
    children = cryptoOptions;
    if (cryptoOptions.length === 0) {
      children = cryptoOptionsOrigin.filter(
        (v) => v.crypto.toUpperCase() === (ACCOUNT_TYPE.SWAP === opt.value ? 'BTC' : 'USDT')
      );
    }
    if (!positiveTransfer && sourceAccount === opt.value) {
      // 过滤掉转出的子钱包
      children = children.filter((v) => v.wallet !== wallets[0]);
    }
    if (!positiveTransfer) {
      children = children.filter((v) => v.wallet !== SWAP_BOUNS_WALLET_KEY);
    }
    if (!children.length) {
      return <></>;
    }
  }
  return (
    <div key={i} className='selected-item'>
      <Expand value={(positiveTransfer ? sourceAccount : targetAccount) === opt.value}>
        {({ expand, setExpand }) => (
          <>
            <div className='title' onClick={() => setExpand((v: any) => !v)}>
              <div className='left'>
                {/* <Image src={imgs[opt.value]} height={20} width={20} alt='icon' /> */}
                <CommonIcon name={img} size={20} enableSkin />
                <div>{opt.label}</div>
              </div>
              <div className={clsx('expand', expand && 'active')}>
                <div>{!expand ? LANG('展开') : LANG('收起')}</div>
                <Svg src='/static/images/common/arrow_down.svg' width={12} height={12} className={clsx('arrow')} />
              </div>
            </div>
            {expand && (
              <div className='item-childs'>
                {children.map((v, i) => {
                  const active = activeType && (swapType ? wallet === v.wallet : true);
                  const digit = swapType
                    ? Swap.Assets.getBalanceDigit({
                        withHooks: false,
                        code: v.id,
                      })
                    : undefined;
                  return (
                    <div
                      className={clsx('item-child', active && 'active')}
                      key={i}
                      onClick={() => {
                        setOpen(false);
                        if (!active) {
                          onChange({ value: opt.value, positiveTransfer, wallet: v.wallet });
                        }
                      }}
                    >
                      {swapType && <WalletAvatar size={20} type={v.icon} walletData={Swap.Assets.walletFormat(v)} />}
                      <div className='name'>{v.walletName}</div>
                      <div className='price'>
                        {v?.price?.toFixed(digit)} {v.crypto}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </Expand>
    </div>
  );
};
